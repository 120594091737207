export const Divide: React.FunctionComponent<
  React.HTMLAttributes<HTMLHRElement>
> = ({ className = '', ...rest }) => (
  <hr className={`border-gray-300 ${className}`} {...rest} />
);

export const VerticalDivide: React.FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = ({ className = '', ...rest }) => (
  <div className={`w-px bg-gray-300 mx-2 h-4 ${className}`} {...rest} />
);
