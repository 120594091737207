import * as React from 'react';

export const ChatList = React.forwardRef<HTMLDivElement, ChatListType>(
  ({ children }, ref) => {
    return (
      <div
        ref={ref}
        className='w-full flex flex-col gap-4 min-h-0 flex-1 overflow-auto px-3 sm:px-5 pt-3 pb-3 sm:pb-5'
        role='list'
      >
        {children}
      </div>
    );
  }
);

type ChatListType = {
  children: React.ReactNode;
};
