import { useRouteMatch } from 'react-router-dom';

import {
  ASKNEEDL_FULLSCREEN_ROUTE,
  SOURCE_NAV_MANAGER_ROUTES,
} from '@/nav-routes';

const ROUTES = [
  '/feeds',
  '/channels',
  '/search',
  '/needlbox',
  '/apps',
  '/notebooks',
  '/connect',
  SOURCE_NAV_MANAGER_ROUTES.BASE_ROUTE,
  ASKNEEDL_FULLSCREEN_ROUTE,
];

export const useFeedRoute = () => useRouteMatch(ROUTES);

export const useFeedRouteExact = () =>
  useRouteMatch(ROUTES.map((route) => `${route}/:category/:subCategory`));
