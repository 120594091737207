// @ts-strict-ignore
import type * as React from 'react';

import type { FeaturesConfigKey } from '@/hooks/query/useFeatureConfig';
import { useFeatureConfigUI } from '@/hooks/query/useFeatureConfig';
import type { FeatureKey } from '@/hooks/query/useFeatureFlag';
import { useFeature } from '@/hooks/query/useFeatureFlag';

export const Feature = ({
  flagkey,
  children,
  fallback = null,
}: FeatureProps) => {
  const { isLoading, flag: flagEnabled } = useFeature(flagkey);

  if (isLoading) {
    return fallback;
  }
  if (!flagEnabled) {
    return null;
  }
  if (!children) {
    return null;
  }

  return children;
};

export const ConfigUI = ({
  feature,
  fallback = null,
  children,
  replacement = null,
}: ConfigUIProps) => {
  const { isLoading, feature: featureEnabled } = useFeatureConfigUI(feature);

  if (isLoading) {
    return fallback;
  }

  if (!featureEnabled) {
    return replacement;
  }

  return children;
};

type FeatureProps = {
  flagkey: FeatureKey;
  children?: React.ReactElement;
  fallback?: React.ReactElement | null;
};

type ConfigUIProps = {
  feature: FeaturesConfigKey;
  children: React.ReactElement | null;
  replacement?: React.ReactElement;
  fallback?: React.ReactElement | null;
};
