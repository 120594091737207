import * as React from 'react';

export const Input = React.forwardRef<HTMLTextAreaElement, InputType>(
  ({ startAdorment, endAdornment, label, rows = 3, ...rest }, ref) => {
    const disabled = rest.disabled;

    return (
      <div
        className={`border border-gray-400 rounded bg-white flex items-end ${
          disabled ? 'bg-gray-200' : ''
        }`.trim()}
      >
        <div className='h-full flex-1 flex items-start'>
          <div className='h-9 flex items-center'>{startAdorment}</div>
          <div className='flex-1 min-w-0 px-2 py-2'>
            <fieldset disabled={disabled} className='disabled:opacity-70 group'>
              <label htmlFor={rest.id} className='sr-only'>
                {label}
              </label>
              <textarea
                {...rest}
                ref={ref}
                rows={rows}
                className='block w-full resize-none max-h-28 border-0 bg-transparent text-gray-900 body-sm placeholder:text-gray-500 placeholder:italic focus:outline-none '
              />
            </fieldset>
          </div>
        </div>
        <div className='h-9 flex items-center'>{endAdornment}</div>
      </div>
    );
  }
);

export const AdornmentContainer = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  return <div {...props} ref={ref} className='flex-shrink-0 self-end' />;
});

type InputType = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  'className' | 'id'
> & {
  startAdorment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  label: string;
  id: string;
};
