import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery } from 'react-query';

import { trackEvent } from '@/analytics/trackEvent';
import { FEATURE_API_ROUTE } from '@/api-routes';
import { axiosInstance } from '@/axios';
import { getEntries, getKeys } from '@/utils/object';

const QUERY_KEYS = ['feature_flag'];

export const useFeatureFlag = ({ enabled = true } = {}) => {
  return useQuery<QueryResponse, unknown, Features>(
    QUERY_KEYS,
    fetchFeatureFlags,
    {
      select: dataProcessing,
      staleTime: Infinity,
      enabled,
    }
  );
};

export const useFeature = (flag: FeatureKey) => {
  const { data, isLoading } = useFeatureFlag();
  return React.useMemo(
    () => ({
      isLoading,
      flag: data?.[flag] ?? false,
    }),
    [isLoading, data, flag]
  );
};

const fetchFeatureFlags = async ({ signal }: QueryFunctionContext) => {
  const { data } = await axiosInstance.get<QueryResponse>(
    FEATURE_API_ROUTE.index(),
    {
      signal,
    }
  );

  trackEvent('feature_flags', data.results);

  return data;
};

const dataProcessing = (data: QueryResponse) => {
  const features = data.results;

  return getEntries<Result>(features).reduce(
    (accumulate, [key]) => ({
      ...accumulate,
      [key]: getFeatureFlagStatus(key, features),
    }),
    initStateFeatures
  );
};

const getFeatureFlagStatus = (
  feature: keyof Features,
  data: Result
): boolean => {
  return Object.keys(FEATURES).includes(feature)
    ? data[feature] === 'on'
    : false;
};

const FEATURES = {
  is_feed_templates: 'is_feed_templates',
  is_onboarding_v2: 'is_onboarding_v2',
  is_comments: 'is_comments',
  is_onboarding_v3: 'is_onboarding_v3',
  is_trending_enabled: 'is_trending_enabled',
  is_chat_bot: 'is_chat_bot',
  is_pricing: 'is_pricing',
  is_sanitisation_v2: 'is_sanitisation_v2',
  is_special_feeds_enabled: 'is_special_feeds_enabled',
  is_webclips: 'is_webclips',
  is_document_public_sharing: 'is_document_public_sharing',
  is_aismarts_feed_creation: 'is_aismarts_feed_creation',
  is_sharable_tags: 'is_sharable_tags',
  is_onboarding_search_modal: 'is_onboarding_search_modal',
  is_sharepoint: 'is_sharepoint',
  is_ask_needl_v2: 'is_ask_needl_v2',
  is_assistants_enabled: 'is_assistants_enabled',
  is_ews_assistant_enabled: 'is_ews_assistant_enabled',
  is_pulse_enabled: 'is_pulse_enabled',
  is_collaborative_feeds: 'is_collaborative_feeds',
  is_whatsapp_assistants_enabled: 'is_whatsapp_assistants_enabled',
  is_feed_summarization: 'is_feed_summarization',
  is_pulse_whatsapp_verification_enabled:
    'is_pulse_whatsapp_verification_enabled',
  // add new features here
} as const;

const initStateFeatures = getKeys(FEATURES).reduce<Features>(
  (acc, key) => ({
    ...acc,
    [key]: false,
  }),
  {} as Features
);

export type FeatureKey = keyof typeof FEATURES;

type Features = {
  [k in FeatureKey]: boolean;
};

type Result = {
  [k in FeatureKey]: 'off' | 'on';
};

type QueryResponse = {
  results: Result;
};
