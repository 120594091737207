import { AccountCircle, Email, Inbox, Language } from '@material-ui/icons';

import { AdmodarLogo } from '@/components/Icons/ADamodar';
import { AnydoLogo } from '@/components/Icons/AnyDo';
import { BloombergQuintLogo } from '@/components/Icons/BloombergQuint';
import { BusinessStandardLogo } from '@/components/Icons/BusinessStandard';
import { EconomistLogo } from '@/components/Icons/Economist';
import { ETLogo } from '@/components/Icons/ET';
import { FasbLogo } from '@/components/Icons/Fasb';
import { FTLogo } from '@/components/Icons/FT';
import { FTAvLogo } from '@/components/Icons/FTAv';
import { GlassdoorLogo } from '@/components/Icons/Glassdoor';
import { GoodnotesLogo } from '@/components/Icons/Goodnotes';
import { HindubusinesslineLogo } from '@/components/Icons/Hindubusinessline';
import { ICloudLogo } from '@/components/Icons/ICloud';
import { ICloudContactsLogo } from '@/components/Icons/IosContacts';
import { KindleLogo } from '@/components/Icons/Kindle';
import { MintLogo } from '@/components/Icons/LiveMint';
import { NotabilityLogo } from '@/components/Icons/Notability';
import { QuandlLogo } from '@/components/Icons/Quandl';
import { StandardnotesLogo } from '@/components/Icons/StandardNotes';
import { TOILogo } from '@/components/Icons/TOI';
import { TuneinradioLogo } from '@/components/Icons/Tuneinradio';
import { ValuepickrLogo } from '@/components/Icons/Valuepickr';
import { ValueWorldLogo } from '@/components/Icons/ValueWorld';
import { WebexLogo } from '@/components/Icons/Webex';
import { WPLogo } from '@/components/Icons/WP';
import { WSJLogo } from '@/components/Icons/WSJ';
import { ZinioLogo } from '@/components/Icons/Zinio';
import AlertInfoIcon from '@/icons/alert_info.svg?react';
import AllFeedsIcon from '@/icons/all_feeds.svg?react';
import AskNeedlChatIcon from '@/icons/ask-needl-button.svg?react';
import AssistantIcon from '@/icons/assistant.svg?react';
import BookmarkLogo from '@/icons/bookmark.svg?react';
import PublicAppsLogo from '@/icons/br-rss.svg?react';
import BuilderLogo from '@/icons/builder.svg?react';
import SavedTaskLogo from '@/icons/bulleted-list-2.svg?react';
import CalenderIcon from '@/icons/calendar.svg?react';
import CommentIcon from '@/icons/chat.svg?react';
import CloseFilledIcon from '@/icons/close-filled.svg?react';
import ConnectedSourcesLogo from '@/icons/connected_sources.svg?react';
import ContractIcon from '@/icons/contract.svg?react';
import ClippedReports from '@/icons/cut.svg?react';
import EditNotebookIcon from '@/icons/edit-notebook.svg?react';
import Exchanges from '@/icons/exchanges.svg?react';
import ExpandIcon from '@/icons/expand.svg?react';
import ExploreIcon from '@/icons/explore.svg?react';
import CollaborativeFeedsLogo from '@/icons/feed-suggest-icon.svg?react';
import FolderIcon from '@/icons/folder-2.svg?react';
import GiftIcon from '@/icons/gift.svg?react';
import HomeLogo from '@/icons/home.svg?react';
import ListViewIcon from '@/icons/list-view.svg?react';
import MageStars from '@/icons/mage_stars.svg?react';
import PrivateAppsLogo from '@/icons/menu.svg?react';
import ModelIcon from '@/icons/model.svg?react';
import MultiFeedChannels from '@/icons/multifeed-channels.svg?react';
import MyFeedsIcon from '@/icons/my_feeds.svg?react';
import NeedlFeedsIcon from '@/icons/needl-feed.svg?react';
import NewsIcon from '@/icons/news.svg?react';
import NotebookIcon from '@/icons/note.svg?react';
import PulseLogo from '@/icons/pulse.svg?react';
import RocketLogo from '@/icons/rocket.svg?react';
import RssAndWebsites from '@/icons/rss-and-websites.svg?react';
import SearchIcon from '@/icons/search.svg?react';
import SendIcon from '@/icons/send.svg?react';
import SharedWithMeFeedIcon from '@/icons/shared_with_me_feed.svg?react';
import StarIcon from '@/icons/star-outline.svg?react';
import StarGradient from '@/icons/StarGradient.svg?react';
import StarWhite from '@/icons/StarWhite.svg?react';
import StatisticUpIcon from '@/icons/statistic-up.svg?react';
import StopIcon from '@/icons/stop.svg?react';
import SummarizationIcon from '@/icons/summarization.svg?react';
import TableIcon from '@/icons/table.svg?react';
import Tags from '@/icons/tag.svg?react';
import TechIcon from '@/icons/tech.svg?react';
import TickFilledIcon from '@/icons/tick-filled.svg?react';
import TickOutlinedIcon from '@/icons/tick-outline.svg?react';
import Trending from '@/icons/trending.svg?react';
import Upload from '@/icons/upload-page.svg?react';
import MyDataLogo from '@/icons/v-split.svg?react';
import AlibabaCloudLogo from '@/logos/alibabacloud.svg?react';
import AmazonLogo from '@/logos/amazon.svg?react';
import AnnualReportsLogo from '@/logos/annual-reports.svg?react';
import AppleMapsLogo from '@/logos/apple-maps.svg?react';
import AppleNotesLogo from '@/logos/apple-notes.svg?react';
import AudibleLogo from '@/logos/audible.svg?react';
import AzureStorageLogo from '@/logos/azure-storage.svg?react';
import BingLogo from '@/logos/bing.svg?react';
import BingTrendsLogo from '@/logos/bing-trends.svg?react';
import BoxLogo from '@/logos/box.svg?react';
import SASTLogo from '@/logos/bse_sdd_sast.svg?react';
import ChromeLogo from '@/logos/chrome.svg?react';
import ConfCallsLogo from '@/logos/conf-calls.svg?react';
import CourseraLogo from '@/logos/coursera.svg?react';
import CRISILLogo from '@/logos/crisil.svg?react';
import DDGLogo from '@/logos/DDG.svg?react';
import Document from '@/logos/documents.svg?react';
import DropboxLogo from '@/logos/drop-box.svg?react';
import EconomicsLogo from '@/logos/economics.svg?react';
import EdgeLogo from '@/logos/edge.svg?react';
import EDxLogo from '@/logos/EdX.svg?react';
import EvernoteLogo from '@/logos/Evernote.svg?react';
import FacebookLogo from '@/logos/facebook.svg?react';
import FBMessengerLogo from '@/logos/fb_messenger.svg?react';
import FeedlyLogo from '@/logos/feedly.svg?react';
import FilingsLogo from '@/logos/filings.svg?react';
import FinanceLogo from '@/logos/finance.svg?react';
import FirefoxLogo from '@/logos/firefox.svg?react';
import FlipboardLogo from '@/logos/flipboard.svg?react';
import FredLogo from '@/logos/fred.svg?react';
import GmailLogo from '@/logos/Gmail.svg?react';
import GoDaddyLogo from '@/logos/go-daddy.svg?react';
import GoogleLogo from '@/logos/google.svg?react';
import GoogleCalendarLogo from '@/logos/google-calendar.svg?react';
import GoogleContactsLogo from '@/logos/google-contacts.svg?react';
import GoogleDriveLogo from '@/logos/google-drive.svg?react';
import GooglePodcastLogo from '@/logos/google-podcasts.svg?react';
import GoogleScholarLogo from '@/logos/google-scholar.svg?react';
import GoogleTrendsLogo from '@/logos/google-trends.svg?react';
import GoToMeetingLogo from '@/logos/GoToMeeting.svg?react';
import HealthcareLogo from '@/logos/healthcare.svg?react';
import IosCalendarLogo from '@/logos/icloudcalendar.svg?react';
import IELogo from '@/logos/ie.svg?react';
import InstagramLogo from '@/logos/instagram.svg?react';
import InstapaperLogo from '@/logos/instapaper.svg?react';
import InvestorPresentationLogo from '@/logos/investor-pres.svg?react';
import KhanAcademyLogo from '@/logos/khan-academy.svg?react';
import LawLogo from '@/logos/law.svg?react';
import Link from '@/logos/link.svg?react';
import LinkedinLogo from '@/logos/linkedin.svg?react';
import MagzterLogo from '@/logos/magzter.svg?react';
import MediaLogo from '@/logos/media.svg?react';
import MediumLogo from '@/logos/medium.svg?react';
import MeetLogo from '@/logos/meet.svg?react';
import TeamsLogo from '@/logos/ms-teams.svg?react';
import NakedCapitalsimLogo from '@/logos/nakedcapitalsim.svg?react';
import NeedlLogo from '@/logos/needl.svg?react';
import NeedlDriveLogo from '@/logos/needldrive.svg?react';
import NeedlNoteLogo from '@/logos/needlnote.svg?react';
import NotionLogo from '@/logos/notion.svg?react';
import NYTLogo from '@/logos/NYT.svg?react';
import OnedriveLogo from '@/logos/Onedrive.svg?react';
import OnenoteLogo from '@/logos/Onenote.svg?react';
import OutlookLogo from '@/logos/outlook.svg?react';
import OutlookCalendarLogo from '@/logos/outlook-calendar.svg?react';
import PcaobLogo from '@/logos/pcaob.svg?react';
import PinterestLogo from '@/logos/pinterest.svg?react';
import PocketLogo from '@/logos/Pocket.svg?react';
import PocketCastLogo from '@/logos/pocket-cast.svg?react';
import ApplePodcastLogo from '@/logos/Podcasts.svg?react';
import QuoraLogo from '@/logos/quora.svg?react';
import RadioPublicLogo from '@/logos/radiopublic.svg?react';
import RedditLogo from '@/logos/reddit.svg?react';
import RSSLogo from '@/logos/RSS.svg?react';
import SafariLogo from '@/logos/safari.svg?react';
import SebiLogo from '@/logos/SEBI.svg?react';
import SECLogo from '@/logos/sec.svg?react';
import SharePointLogo from '@/logos/share-point.svg?react';
import SkypeLogo from '@/logos/Skype.svg?react';
import SlackLogo from '@/logos/slack.svg?react';
import SoundCloudLogo from '@/logos/soundcloud.svg?react';
import Media from '@/logos/source_media.svg?react';
import SpotifyLogo from '@/logos/spotify.svg?react';
import SSRNLogo from '@/logos/ssrn.svg?react';
import StackOverflowLogo from '@/logos/stackoverflow.svg?react';
import StitcherLogo from '@/logos/stitcher.svg?react';
import TechnologyLogo from '@/logos/technology.svg?react';
import TedLogo from '@/logos/TED.svg?react';
import TelegramLogo from '@/logos/telegram.svg?react';
import TickDouble from '@/logos/tick_double.svg?react';
import TodoistLogo from '@/logos/todoist.svg?react';
import TwitterLogo from '@/logos/twitter.svg?react';
import UdacityLogo from '@/logos/udacity.svg?react';
import UdemyLogo from '@/logos/udemy.svg?react';
import VimeoLogo from '@/logos/vimeo.svg?react';
import VjiayMalikLogo from '@/logos/vjiaymalik.svg?react';
import WhatsappLogo from '@/logos/whatsapp.svg?react';
import WikipediaLogo from '@/logos/wiki.svg?react';
import WolfmanLogo from '@/logos/wolfram-alpha.svg?react';
import WordpressLogo from '@/logos/wordpress.svg?react';
import YahooLogo from '@/logos/yahoo.svg?react';
import YoutubeLogo from '@/logos/youtube.svg?react';
import ZoomLogo from '@/logos/zoom.svg?react';

import { isKey } from './type-helpers';

const map = {
  emails: {
    others: Email,
  },
  rss: {
    default: RSSLogo,
  },
  default: {
    home: HomeLogo,
    pulse: PulseLogo,
    builder: BuilderLogo,
    whatsapp: WhatsappLogo,
    telegram: TelegramLogo,
    large_files: Document,
    documents: Document,
    email: GmailLogo,
    gmail: GmailLogo,
    needl: NeedlLogo,
    gmail_crawl: GmailLogo,
    links: Link,
    evernote: EvernoteLogo,
    onedrive: OnedriveLogo,
    onenote: OnenoteLogo,
    needlnote: NeedlNoteLogo,
    needl_notebooks: NeedlNoteLogo,
    facebookmessenger: FBMessengerLogo,
    outlook: OutlookLogo,
    yahoo: YahooLogo,
    teams: TeamsLogo,
    godaddy: GoDaddyLogo,
    webex: WebexLogo,
    slack: SlackLogo,
    zoom: ZoomLogo,
    skype: SkypeLogo,
    googlemeet: MeetLogo,
    gotomeeting: GoToMeetingLogo,
    pocket: PocketLogo,
    instapaper: InstapaperLogo,
    chrome: ChromeLogo,
    firefox: FirefoxLogo,
    edge: EdgeLogo,
    safari: SafariLogo,
    internetexplorer: IELogo,
    googlecalendar: GoogleCalendarLogo,
    outlookcalendar: OutlookCalendarLogo,
    icloudcalendar: IosCalendarLogo,
    googlecontacts: GoogleContactsLogo,
    applecontacts: ICloudContactsLogo,
    microsoftcontacts: OutlookLogo,
    goodnotes: GoodnotesLogo,
    standardnotes: StandardnotesLogo,
    notability: NotabilityLogo,
    notion: NotionLogo,
    applenotes: AppleNotesLogo,
    googledrive: GoogleDriveLogo,
    sharepoint: SharePointLogo,
    needldrive: NeedlDriveLogo,
    dropbox: DropboxLogo,
    box: BoxLogo,
    azure_storage: AzureStorageLogo,
    icloud: ICloudLogo,
    alibabacloud: AlibabaCloudLogo,
    todoist: TodoistLogo,
    anydo: AnydoLogo,
    audible: AudibleLogo,
    kindle: KindleLogo,
    spotify: SpotifyLogo,
    applepodcasts: ApplePodcastLogo,
    soundcloud: SoundCloudLogo,
    tuneinradio: TuneinradioLogo,
    pocketcasts: PocketCastLogo,
    radiopublic: RadioPublicLogo,
    stitcher: StitcherLogo,
    googlepodcasts: GooglePodcastLogo,
    livemint: MintLogo,
    Livemint: MintLogo,
    facebook: FacebookLogo,
    instagram: InstagramLogo,
    linkedin: LinkedinLogo,
    quora: QuoraLogo,
    reddit: RedditLogo,
    Reddit: RedditLogo,
    twitter: TwitterLogo,
    pinterest: PinterestLogo,
    youtube: YoutubeLogo,
    youtubepreminum: YoutubeLogo,
    vimeo: VimeoLogo,
    tedtalks: TedLogo,
    google: GoogleLogo,
    bing: BingLogo,
    duckduckgo: DDGLogo,
    googlescholar: GoogleScholarLogo,
    flipboard: FlipboardLogo,
    magzter: MagzterLogo,
    zinio: ZinioLogo,
    wordpress: WordpressLogo,
    medium: MediumLogo,
    valuepickr: ValuepickrLogo,
    Valuepickr: ValuepickrLogo,
    wolframalpha: WolfmanLogo,
    ssrn: SSRNLogo,
    'ftalphaville.ft': FTAvLogo,
    ftalphaville: FTAvLogo,
    'FT Alphaville': FTAvLogo,
    valueinvestingworld: ValueWorldLogo,
    'Value Investing World': ValueWorldLogo,
    adamodar: AdmodarLogo,
    wikipedia: WikipediaLogo,
    stackoverflow: StackOverflowLogo,
    feedly: FeedlyLogo,
    wsj: WSJLogo,
    'Wall Street Journal': WSJLogo,
    ft: FTLogo,
    'Financial Times': FTLogo,
    nyt: NYTLogo,
    washingtonpost: WPLogo,
    economist: EconomistLogo,
    Economist: EconomistLogo,
    bloombergquint: BloombergQuintLogo,
    'Bloomberg Quint': BloombergQuintLogo,
    businessstandard: BusinessStandardLogo,
    'business-standard': BusinessStandardLogo,
    'Business Standard': BusinessStandardLogo,
    thehindubusinessline: HindubusinesslineLogo,
    'Hindu Business Line': HindubusinesslineLogo,
    economictimes: ETLogo,
    'economictimes.indiatimes': ETLogo,
    'Economic Times': ETLogo,
    timesofindia: TOILogo,
    googletrends: GoogleTrendsLogo,
    quandl: QuandlLogo,
    glassdoor: GlassdoorLogo,
    amazonreviews: AmazonLogo,
    applemobility: AppleMapsLogo,
    bingwebmastertools: BingTrendsLogo,
    coursera: CourseraLogo,
    khanacademy: KhanAcademyLogo,
    udacity: UdacityLogo,
    udemy: UdemyLogo,
    edx: EDxLogo,
    'sebi.gov': SebiLogo,
    SEBI: SebiLogo,
    'sec.gov': SECLogo,
    SEC: SECLogo,
    fasb: FasbLogo,
    'fasb.org': FasbLogo,
    FASB: FasbLogo,
    pcaobus: PcaobLogo,
    PCAOBUS: PcaobLogo,
    fred: FredLogo,
    FRED: FredLogo,
    paulkrugmansblog: NYTLogo,
    'krugman.blogs.nytimes': NYTLogo,
    "Paul Krugman's Blog": NYTLogo,
    nakedcapitalism: NakedCapitalsimLogo,
    'Naked Capitalism': NakedCapitalsimLogo,
    drvijaymalik: VjiayMalikLogo,
    vijaymalik: VjiayMalikLogo,
    'Dr Vijay Malik': VjiayMalikLogo,
    websitecrawl: Language,
    bse_annualreports: AnnualReportsLogo,
    bse_annual_reports: AnnualReportsLogo,
    'Annual Reports': AnnualReportsLogo,
    'BSE Annual Reports': AnnualReportsLogo,
    bse_confcalls: ConfCallsLogo,
    bse_conf_calls: ConfCallsLogo,
    'Conf Calls': ConfCallsLogo,
    earnings_conf_calls: ConfCallsLogo,
    'Earnings Call Transcripts - India': ConfCallsLogo,
    bse_filings: FilingsLogo,
    Filings: FilingsLogo,
    'BSE Filings': FilingsLogo,
    bse_presentations: InvestorPresentationLogo,
    Presentations: InvestorPresentationLogo,
    'BSE Presentations': InvestorPresentationLogo,
    india_regulatory_data: Exchanges,
    us_regulatory_data: Exchanges,
    media: Media,
    'telegram (public)': TelegramLogo,
    rss: RSSLogo,
    hdd: Inbox,
    economics: EconomicsLogo,
    finance: FinanceLogo,
    healthcare: HealthcareLogo,
    law: LawLogo,
    rssMedia: MediaLogo,
    technology: TechnologyLogo,
    clipped_reports: ClippedReports,
    myData: MyDataLogo,
    saved_tasks: SavedTaskLogo,
    bookmarks: BookmarkLogo,
    private_apps_nav: PrivateAppsLogo,
    public_apps_nav: PublicAppsLogo,
    trending: Trending,
    exchanges: Exchanges,
    rss_and_websites: RssAndWebsites,
    feed_bundle: GiftIcon,
    list_view: ListViewIcon,
    star: StarIcon,
    tags: Tags,
    notebook: NotebookIcon,
    my_feeds: MyFeedsIcon,
    needl_feeds: NeedlFeedsIcon,
    shared_with_me_feed: SharedWithMeFeedIcon,
    comments: CommentIcon,
    assistants: AssistantIcon,
    alert_info: AlertInfoIcon,
    close_filled: CloseFilledIcon,
    tick_filled: TickFilledIcon,
    tick_outlined: TickOutlinedIcon,
    search: SearchIcon,
    all_feeds: AllFeedsIcon,
    connected_sources: ConnectedSourcesLogo,
    edit_notebook: EditNotebookIcon,
    multifeed_channels: MultiFeedChannels, // Icon for legacy groups feature
    folder: FolderIcon,
    bse_sdd_sast: SASTLogo,
    summarization_icon: SummarizationIcon,
    statistic_up_icon: StatisticUpIcon,
    model_icon: ModelIcon,
    calendar_icon: CalenderIcon,
    tech_icon: TechIcon,
    upload_icon: Upload,
    tick_double: TickDouble,
    table_icon: TableIcon,
    news_icon: NewsIcon,
    crisil: CRISILLogo,
    merge_suggestion_icon: CollaborativeFeedsLogo,
    review_suggestion_icon: CollaborativeFeedsLogo,
    ask_needl_header_star: StarWhite,
    ask_needl_initial_messages_rocket: RocketLogo,
    ask_needl_star_gradient: StarGradient,
    ask_needl_send_icon: SendIcon,
    ask_needl_chat_icon: AskNeedlChatIcon,
    ask_needl_expand_icon: ExpandIcon,
    ask_needl_contract_icon: ContractIcon,
    ask_needl_stars: MageStars,
    ask_needl_stop_logo: StopIcon,
    explore_icon: ExploreIcon,
  },
} as const;

export const getLogo = (
  source: string,
  category: 'default' | 'emails' | 'rss' = 'default'
) => {
  if (!map[category] || !isKey(category, map)) {
    return AccountCircle;
  }

  if (isKey(source, map[category])) {
    return map[category][source];
  } else if (isKey(source, map['default'])) {
    return map['default'][source];
  } else if (category !== 'default') {
    const key = 'default';

    if (isKey(key, map[category])) {
      return map[category][key];
    }
  }

  return AccountCircle;
};
