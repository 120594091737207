import { Minimize } from '@styled-icons/material';
import * as React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import tw, { css, styled } from 'twin.macro';

import { trackEvent } from '@/analytics/trackEvent';
import { IconButton } from '@/components/IconButton/IconButton';
import { useOverlayWidthContext } from '@/components/Preview/hooks/useOverlayWidthDetails';
import { useNavigate } from '@/router';
import { getLogo } from '@/utils/getIcons';

export const Header = () => {
  const { url } = useRouteMatch();
  const previewMatch = useRouteMatch('/preview');
  const { overlayWidthVWDetails, setOverlayWidthVWDetails } =
    useOverlayWidthContext();

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const HeaderStar = getLogo('ask_needl_header_star');
  const ExpandIcon = getLogo('ask_needl_expand_icon');
  const ContractIcon = getLogo('ask_needl_contract_icon');

  const handleClose = React.useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    if (
      searchParams.has('overlay-type') &&
      searchParams.get('overlay-type') === 'ask-needl'
    ) {
      searchParams.delete('overlay-type');
    } else if (
      searchParams.has('overlay-preview_askneedl') &&
      searchParams.get('overlay-type') === 'preview'
    ) {
      searchParams.delete('overlay-preview_askneedl');
    }

    navigate.push(
      {
        pathname: url,
        search: searchParams.toString(),
      },
      null,
      { removeOldQueryParams: true, removeOldState: false }
    );
  }, [location, navigate, url]);

  const hideClose = !!previewMatch;

  const handleMaximizeToggle = () => {
    const toIncrease =
      overlayWidthVWDetails.current === overlayWidthVWDetails.min;
    trackEvent(
      toIncrease ? 'askneedl_window_maximized' : 'askneedl_window_minimized'
    );
    setOverlayWidthVWDetails({
      widthType: 'current',
      width: toIncrease ? overlayWidthVWDetails.max : overlayWidthVWDetails.min,
    });
  };

  const showBorder = !(
    searchParams.get('overlay-type') === 'preview' || !!previewMatch
  );

  return (
    <HeaderBgWrapper
      size={hideClose ? 'small' : 'default'}
      className={showBorder ? 'rounded-tl-md' : ''}
    >
      <div className='h-8 flex items-center justify-center relative'>
        {hideClose ? null : (
          <div className='absolute left-0'>
            {searchParams.get('overlay-type') !== 'preview' && (
              <IconButton
                hoverBg={false}
                label='close bot'
                size='xs'
                onClick={handleMaximizeToggle}
              >
                {overlayWidthVWDetails.current === overlayWidthVWDetails.min ? (
                  <ExpandIcon className='text-white scale-110' />
                ) : (
                  <ContractIcon className='text-white' />
                )}
              </IconButton>
            )}
          </div>
        )}
        <div className='flex items-center gap-4 pt-2'>
          <div>
            <HeaderStar />
          </div>
          <div className='text-white font-bold tracking-wide leading-7'>
            AskNeedl
          </div>

          {!hideClose && (
            <div className='absolute right-0'>
              <IconButton
                hoverBg={false}
                label='close bot'
                size='xs'
                onClick={handleClose}
              >
                <Minimize className='text-white -mt-2 scale-x-150' />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </HeaderBgWrapper>
  );
};

const HeaderBgWrapper = styled.div<{ size: 'default' | 'small' }>(
  ({ size }) => [
    size === 'small' ? tw`py-0.5` : tw`pt-0.5 pb-1`,
    tw`px-3`,
    css`
      background: var(
        --Gradient-Button,
        linear-gradient(270deg, #367d8d -12.61%, #57be86 153.6%)
      );
    `,
  ]
);
