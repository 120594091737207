import debounce from 'lodash.debounce';
import * as React from 'react';

import { IconButton } from '@/components/IconButton/IconButton';
import { withAskNeedlStateSlice } from '@/features/ChatBotV2/hooks/withStateSlice';
import type { Message } from '@/features/ChatBotV2/types';
import UpArrow from '@/icons/up-lg.svg?react';

const ScrollToBottomButtonImpl: React.FC<ScrollToBottomButtonProps> = ({
  scrollRef,
  messages,
}) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const getScrollContainer = React.useCallback(() => {
    return scrollRef?.current || document.documentElement;
  }, [scrollRef]);

  const scrollToBottom = React.useCallback(() => {
    const scrollContainer = getScrollContainer();
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: scrollContainer.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [getScrollContainer]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = React.useCallback(
    debounce(() => {
      const scrollContainer = getScrollContainer();
      if (scrollContainer && buttonRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
        const atBottom = scrollTop + clientHeight >= scrollHeight - 1;

        buttonRef.current.style.display = atBottom ? 'none' : 'block';
      }
    }, 100),
    [getScrollContainer]
  );

  React.useEffect(() => {
    const target = scrollRef?.current || window;
    target.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      target.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [scrollRef, handleScroll]);

  React.useEffect(() => {
    const container = scrollRef?.current;
    if (container) {
      const resizeObserver = new ResizeObserver(() => {
        handleScroll();
      });

      resizeObserver.observe(container);

      return () => {
        resizeObserver.unobserve(container);
        resizeObserver.disconnect();
      };
    }
  }, [scrollRef, handleScroll]);

  React.useEffect(() => {
    handleScroll();
  }, [messages, handleScroll]);

  return (
    <div className='rotate-180'>
      <IconButton
        className='bg-gray-300 hidden'
        size='small'
        ref={buttonRef}
        onClick={scrollToBottom}
      >
        <UpArrow className='fill-gray-700' />
      </IconButton>
    </div>
  );
};

export const ScrollToBottomButton = withAskNeedlStateSlice<
  Record<string, unknown>,
  ScrollToBottomButtonProps
>(ScrollToBottomButtonImpl, (props, state) => ({
  ...props,
  messages:
    state.data.sessions[state.data.searchWithin][
      state.data.currentFeedOrDocumentId
    ].messages,
}));

type ScrollToBottomButtonProps = {
  /**
   * Scroll container ref (optional, defaults to body)
   */
  scrollRef?: React.RefObject<HTMLDivElement>;
  /**
   * To poll if scroll is at the bottom on messages change
   */
  messages?: Message[];
};
