import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { useNavigate } from '@/router';
import { nativeEvent } from '@/utils/native-event';

import { useDevices } from './useDevices';
import { useGetLandingPage } from './useGetLandingPage';

export const useBackNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { platform } = useDevices();
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);
  const landingPageRoute = useGetLandingPage();

  // https://stackoverflow.com/a/25524865/9001097
  return React.useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    const prevPathname = location.pathname;

    navigate.goBack();

    timerRef.current = setTimeout(() => {
      if (window.location.pathname === prevPathname) {
        if (platform === 'android') {
          nativeEvent(['back', '']);
          return;
        }
        navigate.push(landingPageRoute);
      }
    }, 200);
  }, [location, navigate, platform, landingPageRoute]);
};
