import { useRouteMatch } from 'react-router-dom';

import { useFeatureConfigUI } from '@/hooks/query/useFeatureConfig';
import { useFeature } from '@/hooks/query/useFeatureFlag';
import { useResponsiveConstants } from '@/hooks/responsive/useResponsiveConstants';
import { ASKNEEDL_FULLSCREEN_ROUTE, SEARCH_ROUTES } from '@/nav-routes';

export const useShowAskNeedlButton = () => {
  const { flag, isLoading } = useFeature('is_ask_needl_v2');
  const { isLoading: isFeatureConfigLoading, feature } =
    useFeatureConfigUI('ask_needl');
  const { mobile } = useResponsiveConstants();
  const isSettings = useRouteMatch('/settings');
  const isNoteBooksRoute = useRouteMatch('/notebooks');
  const isAssitantsRoute = useRouteMatch('/managers/assistants');
  const isNeedlBoxRoute = useRouteMatch('/needlbox');
  const isSearchPageRoute = useRouteMatch(SEARCH_ROUTES.FULLSCREEN);
  const isAskNeedlPageRoute = useRouteMatch(ASKNEEDL_FULLSCREEN_ROUTE);

  return (
    !isLoading &&
    flag &&
    !mobile &&
    !isSettings &&
    !isAssitantsRoute &&
    !isNoteBooksRoute &&
    !isNeedlBoxRoute &&
    !isSearchPageRoute &&
    !isFeatureConfigLoading &&
    !isAskNeedlPageRoute &&
    feature
  );
};
