import { ConfigUI } from '@/components/Feature';
import { ChatContainer } from '@/features/ChatBotV2/components/ChatContainer';
import { Footer } from '@/features/ChatBotV2/components/Footer';
import { SaveToNoteButton } from '@/features/ChatBotV2/components/SaveToNoteButton';
import { withAskNeedlStateSlice } from '@/features/ChatBotV2/hooks/withStateSlice';

import { Notice } from './Notice';
import type { useChatPanelPropsType } from './useChatPanelCommonProps';
import { useChatPanelCommonProps } from './useChatPanelCommonProps';

const ChatPanelImpl = ({
  currentFeedOrDocumentId,
  searchWithin,
  sessionState,
}: ChatPanelImplType) => {
  const {
    isLoading,
    isStreaming,
    isError,
    handleAskQuestion,
    handleClear,
    saveToNoteHandler,
    snipNoteMutation,
    listContainerRef,
    stopGenerating,
  } = useChatPanelCommonProps({
    currentFeedOrDocumentId,
    searchWithin,
    sessionState,
  });

  return (
    <section className='flex-1 flex flex-col pb-2 overflow-hidden z-10 h-full p-2 max-w-4xl mx-auto'>
      <ChatContainer
        listContainerRef={listContainerRef}
        isLoading={isLoading}
        isError={isError}
        handleAskQuestion={handleAskQuestion}
      />
      <ConfigUI feature='notebooks'>
        {sessionState.messages.length < 2 ? null : (
          <SaveToNoteButton
            disabled={isLoading || isError}
            loading={snipNoteMutation.isLoading}
            saveToNoteHandler={saveToNoteHandler}
          />
        )}
      </ConfigUI>
      <Footer
        listContainerRef={listContainerRef}
        scrollContainerRef={listContainerRef}
        onSubmit={handleAskQuestion}
        handleClear={handleClear}
        isLoading={snipNoteMutation.isLoading}
        hasMessages={sessionState.messages.length > 0}
        stopGenerating={stopGenerating}
        isStreaming={isLoading || isStreaming}
        rows={3}
      />
      <Notice />
    </section>
  );
};

export const ChatPanel = withAskNeedlStateSlice<
  Record<string, unknown>,
  ChatPanelImplType
>(ChatPanelImpl, (props, state) => {
  return {
    ...props,
    searchWithin: state.data.searchWithin,
    currentFeedOrDocumentId: state.data.currentFeedOrDocumentId,
    sessionState:
      state.data.sessions[state.data.searchWithin][
        state.data.currentFeedOrDocumentId
      ],
  };
});

type ChatPanelImplType = useChatPanelPropsType;
