import * as React from 'react';

import { useFetchPrivateApps } from '@/hooks/query/useFetchPrivateApps';
import { useFetchPublicApps } from '@/hooks/query/useFetchPublicApps';

export const useUserHasData = () => {
  const { data: privateData, isLoading: isPrivateLoading } =
    useFetchPrivateApps();
  const { data: publicData, isLoading: isPublicLoading } = useFetchPublicApps();

  return React.useMemo(() => {
    const privateDataExist = privateData && privateData.length > 0;
    const publicDataExist = publicData && publicData.length > 0;

    return {
      hasData: !!privateDataExist || !!publicDataExist,
      isLoading: isPrivateLoading || isPublicLoading,
    };
  }, [privateData, publicData, isPrivateLoading, isPublicLoading]);
};
