// @ts-strict-ignore
import { parse } from 'querystring';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const { search } = useLocation();
  const queryParams = React.useMemo(() => parse(search.slice(1)), [search]);

  const query = React.useMemo(
    () => ({
      ...(queryParams.search && { q: queryParams.search }),
      ...(queryParams.search_type && {
        search_type: queryParams.search_type,
      }),
      ...(queryParams.search_page && { search_page: queryParams.search_page }),
      ...(queryParams.exact !== undefined &&
        queryParams.exact !== null && { exact_match: queryParams.exact }),
      ...(queryParams.spell_correct !== undefined &&
        queryParams.spell_correct !== null && {
          spell_correct: queryParams.spell_correct,
        }),
      ...(queryParams.channel_id !== undefined &&
        queryParams.channel_id !== null && {
          channel_id: queryParams.channel_id,
        }),
      ...(queryParams.board_id !== undefined &&
        queryParams.board_id !== null && {
          board_id: queryParams.board_id,
        }),
      ...(queryParams.access_key !== undefined &&
        queryParams.access_key !== null && {
          access_key: queryParams.access_key,
        }),
      ...(queryParams.public_access_key !== undefined &&
        queryParams.public_access_key !== null && {
          public_access_key: queryParams.public_access_key,
        }),
      ...(!!queryParams.category && { category: queryParams.category }),
      ...(!!queryParams.category_tab && {
        category_tab: queryParams.category_tab,
      }),
      ...(!!queryParams.searchTerm && {
        searchTerm: queryParams.searchTerm,
      }),
      ...(!!queryParams.global_channel_id && {
        global_channel_id: queryParams.global_channel_id,
      }),
      ...(!!queryParams.comment_id && {
        comment_id: queryParams.comment_id,
      }),
      ...(!!queryParams.comments && { comments: queryParams.comments }),
      ...(!!queryParams.redirect && { redirect: queryParams.redirect }),
    }),
    [queryParams]
  );

  const queryParamsStringValues = React.useMemo(() => {
    return Object.entries(queryParams).reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        return { ...acc, [key]: value[0] };
      }
      return { ...acc, [key]: value };
    }, {} as Record<string, string>);
  }, [queryParams]);

  return [query, queryParams, queryParamsStringValues] as const;
};
