import type { Placement } from '@popperjs/core';
import ReachPortal from '@reach/portal';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import * as React from 'react';
import FocusLock from 'react-focus-lock';
import { usePopper } from 'react-popper';
import { RemoveScroll } from 'react-remove-scroll';

import type { VariantKeys } from '@/animation/common-variants';
import { variants } from '@/animation/common-variants';
import { useForkRef } from '@/hooks/useForkRef';
import { useHandleClickOutside } from '@/hooks/useHandleClickOutside';

export interface PopperOffset {
  vertical: number;
  horizontal: number;
}

export type { Placement };

export type PopperType = {
  animationVariant?: VariantKeys;
  /**
   * should the first focusable element be focused
   */
  autoFocus?: boolean;
  /**
   * should the popper have a background overlay
   */
  backgroundOverlay?: boolean;
  /**
   * popper class names
   */
  className?: string;
  /**
   * children node
   */
  children: React.ReactNode;
  /**
   * function to close the popper
   */
  closePopper?: () => void;
  /**
   * boolean to determine if the popper closes on outside click
   */
  closeOnOutSideClick?: boolean;
  disableFocusLock?: boolean;
  /**
   * boolean to toggle the visibility of the popper
   */
  isVisible: boolean;
  /**
   * should remove window scroll
   */
  removeScrollEnabled?: boolean;
  /**
   * should return focus
   */
  returnFocus?: boolean;
  /**
   * popper offset
   */
  offset?: PopperOffset;
  /**
   * Popper placement.
   */
  placement?: Placement;
  /**
   * inline styles
   */
  styles?: React.CSSProperties;
  /**
   * target reference to position the popper
   */
  targetRef: React.MutableRefObject<HTMLElement | null>;

  /**
   * Show animation
   */
  showAnimation?: boolean;
};

export const Popper = React.forwardRef<HTMLDivElement, PopperType>(
  (
    {
      animationVariant = 'drop-in',
      autoFocus = false,
      backgroundOverlay = false,
      className,
      targetRef,
      isVisible,
      placement = 'bottom',
      children,
      disableFocusLock = false,
      returnFocus = true,
      removeScrollEnabled = true,
      offset = { horizontal: 0, vertical: 10 },
      closePopper,
      closeOnOutSideClick = true,
      styles: forwardedStyles,
      showAnimation = true,
    },
    forwardRef
  ) => {
    const reducedMotion = useReducedMotion();
    const popperRef = React.useRef<HTMLDivElement>(null);

    const { ref: DropDownRefnew } = useHandleClickOutside({
      neglectElement: targetRef,
      callback: () => closePopper?.(),
    });

    const combineForwardRef = useForkRef(popperRef, forwardRef);
    const combinedRef = useForkRef(combineForwardRef, DropDownRefnew);

    const ref = closeOnOutSideClick ? combinedRef : combineForwardRef;

    const [arrowElement, setArrowElement] =
      React.useState<HTMLDivElement | null>(null);

    const { horizontal, vertical } = offset;

    const { styles, attributes } = usePopper(
      targetRef?.current,
      popperRef?.current,
      {
        placement,
        modifiers: [
          { name: 'arrow', options: { element: arrowElement } },
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: [horizontal, vertical],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              altAxis: true,
            },
          },
        ],
      }
    );

    React.useEffect(() => {
      function handleKeyDown(e: KeyboardEvent) {
        if (e.key === 'Escape') {
          closePopper?.();
        }
      }
      window.addEventListener('keydown', handleKeyDown, false);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [closePopper]);

    const Wrapper = showAnimation ? AnimatePresence : 'div';

    return (
      <Wrapper initial={false}>
        {isVisible ? (
          <ReachPortal>
            <FocusLock
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
              returnFocus={returnFocus}
              disabled={disableFocusLock}
            >
              <RemoveScroll
                enabled={removeScrollEnabled}
                removeScrollBar={false}
              >
                {backgroundOverlay ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ type: 'spring', damping: 25, stiffness: 200 }}
                    className='bg-black bg-opacity-60 fixed inset-0'
                  />
                ) : null}
                <div
                  ref={ref}
                  style={{
                    position: 'relative',
                    isolation: 'isolate',
                    zIndex: 1,
                    ...styles.popper,
                    ...forwardedStyles,
                  }}
                  {...attributes.popper}
                  data-needl-portal-popper
                  className={className}
                >
                  <motion.div
                    exit='exit'
                    initial='initial'
                    animate='animate'
                    variants={
                      reducedMotion
                        ? variants['fade-in-fast']
                        : variants[animationVariant]
                    }
                  >
                    {children}
                    <div ref={setArrowElement} style={styles.arrow} />
                  </motion.div>
                </div>
              </RemoveScroll>
            </FocusLock>
          </ReachPortal>
        ) : null}
      </Wrapper>
    );
  }
);
