import type * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { useNavState } from '@/context/NavContext';
import { useResponsiveConstants } from '@/hooks/responsive/useResponsiveConstants';

/**
 * Position fixed relative to content page was not working,
 * because of transform property to content page by framer motion.
 * This is position fixed with respect to viewport,
 * left padding given to subtract offset of sidebar.
 * Available width for children will be equal to that of content page.
 * Adjust the vertical placement by chosing top or bottom type.
 * Offset will be top: <offSet>px or bottom: <offSet>px, depending on type.
 */
export const FixedContainer = ({
  type,
  offset = 0,
  children,
}: FixedContainerType) => {
  const { state } = useNavState();
  const { laptopAndBelow } = useResponsiveConstants();
  const isNavOpen = state === 'open' || state === 'opened';

  return (
    <Container
      laptopAndBelow={laptopAndBelow}
      isNavOpen={isNavOpen}
      type={type}
      offset={offset}
    >
      {children}
    </Container>
  );
};

const Container = styled.div<
  Pick<FixedContainerType, 'offset' | 'type'> & {
    laptopAndBelow: boolean;
    isNavOpen: boolean;
  }
>`
  ${tw`fixed w-full left-0 z-10`}
  padding-left: ${({ laptopAndBelow, isNavOpen }) =>
    laptopAndBelow
      ? '0'
      : isNavOpen
      ? 'var(--side-nav-width)'
      : 'var(--side-nav-closed-width)'};
  ${({ type, offset }) =>
    type === 'top' ? `top: ${offset}px;` : `bottom: ${offset}px;`}
`;

type FixedContainerType = {
  type: 'bottom' | 'top';
  offset?: number;
  children: React.ReactNode;
};
