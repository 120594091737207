import * as React from 'react';

import { ML_API_ROUTES } from '@/api-routes';
import type { ContentEvent } from '@/hooks/useSSERequest';
import { useSSERequest } from '@/hooks/useSSERequest';

const ASK_NEEDL_CONTENT_STATUS = 'GENERATED_ANSWER';

export const useAskNeedl = () => {
  const [streamingAnswer, setStreamingAnswer] = React.useState('');
  const closeSSEConnectionRef = React.useRef<() => void>();

  const {
    isLoading,
    isStreaming,
    isError,
    events,
    data,
    makeSSERequest,
    clearData,
  } = useSSERequest<AskNeedlResponseType, string>(false);

  const handleContentEvent = React.useCallback(
    (eventData: ContentEvent<string>) => {
      setStreamingAnswer((prevState) => prevState + eventData.data);
    },
    []
  );

  const askNeedl = async ({
    prompt,
    source,
    session_id,
    document_id,
    board_id,
    channel_id,
  }: AskNeedlParamsType) => {
    const urlSearchParams = new URLSearchParams([
      ['prompt', prompt],
      ['stream', 'true'],
      ['timezone', Intl.DateTimeFormat().resolvedOptions().timeZone],
      ...(source ? [['source', source]] : []),
      ...(session_id ? [['session_id', session_id]] : []),
      ...(document_id ? [['document_id', document_id]] : []),
      ...(board_id ? [['board_id', board_id]] : []),
      ...(channel_id ? [['channel_id', channel_id]] : []),
    ]);

    const closeSSEConnection = await makeSSERequest(
      `${ML_API_ROUTES.askNeedl()}?${urlSearchParams.toString()}`,
      {
        method: 'GET',
      },
      ASK_NEEDL_CONTENT_STATUS,
      handleContentEvent
    );

    closeSSEConnectionRef.current = closeSSEConnection;
  };

  const clearEventData = () => {
    setStreamingAnswer('');
    clearData();
    if (closeSSEConnectionRef.current) {
      closeSSEConnectionRef.current();
    }
  };

  return {
    isLoading,
    isStreaming,
    isError,
    data,
    streamingAnswer,
    events,
    askNeedl,
    clearEventData,
  };
};

type AskNeedlParamsType = {
  prompt: string;
  source?: string;
  session_id?: string;
  document_id?: string;
  board_id?: string;
  channel_id?: string;
};

type AskNeedlRetreivedResultType = {
  source: string;
  document_id: string;
  highlights: string[];
  needl_document_link?: string;
  original_source_link?: string;
  category_tab: string;
  access_key: string;
  utc_timestamp: number;
};

export type AskNeedlSentenceType = {
  sentence: string;
  citations: AskNeedlCitationType[];
};

export type AskNeedlResponseType = {
  generated_answer: {
    answer: string;
    sentences: AskNeedlSentenceType[];
  };
  retrieved_results?: AskNeedlRetreivedResultType[];
  message_id: string;
  session_id: string;
  answer_quality?: 'HIGH' | 'LOW';
  app_version: string;
  query_params: {
    category: string;
    prompt: string;
    session_id?: string;
    document_id?: string;
    generate_answer?: boolean;
    stream?: boolean;
  };
};

export type AskNeedlCitationType = {
  id: number;
  source: string;
  category_tab: string;
  document_id: string;
  access_key: string;
  context: string;
  needl_document_link?: string;
  original_source_link?: string;
  url?: string;
  title_label: string;
  source_label: string;
  content_only: string;
  highlight_indexes: { start: number; end: number }[];
};
