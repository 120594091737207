import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { FEATURE_API_ROUTE } from '@/api-routes';
import { axiosInstance } from '@/axios';

const QUERY_KEYS = ['feature_config'];

const FEATURES = {
  /** core_features */
  assistants: 'assistants',
  ask_needl: 'ask_needl',
  search: 'search',

  /** data_management */
  my_data: 'my_data',
  clipped_reports: 'clipped_reports',
  bookmarks: 'bookmarks',
  tags: 'tags',
  notebooks: 'notebooks',
  needldrive: 'needldrive',
  portfolio_watchlist: 'portfolio_watchlist',

  /** connected_sources */
  apps: 'apps',
  websites: 'websites',
  capital_markets: 'capital_markets',

  /** collaboration */
  comments: 'comments',
  group_feeds: 'group_feeds',
  suggest_sources: 'suggest_sources',
  publish_feeds: 'publish_feeds',
  copy_feed_template: 'copy_feed_template',
  exit_feed: 'exit_feed',
  shared_tags: 'shared_tags',

  /** others */
  explore_menu: 'explore_menu',
  my_feeds: 'my_feeds',
  needl_feeds: 'needl_feeds',

  /** assistant_types */
  portfolio_summarization_assistant: 'portfolio_summarization_assistant',
  early_warning_signal_assistant: 'early_warning_signal_assistant',
  feed_summary_assistant: 'feed_summary_assistant',

  /** data_insights */
  doc_summarization: 'doc_summarization',
  doc_sentiment_analysis: 'doc_sentiment_analysis',
  doc_table_extraction: 'doc_table_extraction',

  /** data_actions */
  doc_download: 'doc_download',
  doc_delete: 'doc_delete',
  feed_filters: 'feed_filters',
  feed_sorting: 'feed_sorting',
  doc_share: 'doc_share',

  /** display_options */
  doc_full_screen_view: 'doc_full_screen_view',
  doc_open_in_source: 'doc_open_in_source',
  doc_open_in_new_tab: 'doc_open_in_new_tab',
  doc_overlay: 'doc_overlay',
  category_tabs: 'category_tabs',
  doc_mark_as_read: 'doc_mark_as_read',
  save_search: 'save_search',
  website_priority: 'website_priority',
  website_unfollow: 'website_unfollow',
  doc_hide_source: 'doc_hide_source',

  /** managers */
  feed_manager: 'feed_manager',
  tags_manager: 'tags_manager',
  contacts_manager: 'contacts_manager',
  needl_storage: 'needl_storage',

  /** notification_and_alerts */
  notification: 'notification',
  app_synced_panel: 'app_synced_panel',

  /** support_and_information */
  help_center: 'help_center',
  whats_new: 'whats_new',
  chat_with_us: 'chat_with_us',
  submit_feedback: 'submit_feedback',
  terms_of_service: 'terms_of_service',
};

export const useConfigUI = () => {
  return useQuery(QUERY_KEYS, fetchFeaturesConfig, {
    select: (response) => response.features,
    staleTime: Infinity,
  });
};

export const useFeatureConfigUI = (feature: FeaturesConfigKey) => {
  const { data, isLoading } = useConfigUI();
  return React.useMemo(() => {
    return {
      isLoading,
      feature: data?.[feature] ?? false,
    };
  }, [isLoading, data, feature]);
};

export const usePrefetchConfigUI = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(QUERY_KEYS, {
      queryFn: fetchFeaturesConfig,
      staleTime: Infinity,
    });
  }, [queryClient]);
};

const fetchFeaturesConfig = async () => {
  const { data } = await axiosInstance.get<Response>(
    FEATURE_API_ROUTE.config()
  );

  return data;
};

export type FeaturesConfigKey = keyof typeof FEATURES;

export type FeaturesConfigType = {
  [k in FeaturesConfigKey]: boolean;
};

type Response = {
  features: FeaturesConfigType;
};
